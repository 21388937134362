import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import ContactForm from "../components/contactForm";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import { checkPropertiesForNull } from "../utils/object";
import { SafeHtmlParser } from "../components/safe-html-parser";
import uuid4 from "uuid4";
import { BgImage } from "gbimage-bridge";
import LazyLoad from "react-lazyload";

const CoursesAndClassesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "courses-and-classes" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        coursesPageFieldGroups {
          coursesAboutSection {
            content
            heading
            subHeading
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          coursesAimSection {
            belowContent
            content
            heading
            aimElements {
              heading
              icon {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          coursesContactSection {
            buttonLabel
            formHeading
          }
          coursesFaqSection {
            heading
            faq {
              ... on WpFaq {
                id
                faqFields {
                  answer
                  page
                  question
                }
              }
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          coursesFullWithTextSection {
            content
            heading
          }
          coursesIntroSection {
            description
            heading
            subHeading
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            cta {
              target
              title
              url
            }
          }
          coursesJourneySection {
            heading
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            cta {
              target
              title
              url
            }
          }
          coursesPricingSection {
            description
            heading
            cta {
              target
              title
              url
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          coursesStorySection {
            description
            heading
            video {
              altText
              mediaItemUrl
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          coursesTextOnlySection {
            content
            heading
          }
          coursesTextWithBackgroundImageSection {
            content
            heading
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          coursesTrainingSection {
            description
            heading
            imageGallery {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, coursesPageFieldGroups },
  } = data;

  const {
    coursesAboutSection,
    coursesAimSection,
    coursesContactSection,
    coursesFaqSection,
    coursesFullWithTextSection,
    coursesIntroSection,
    coursesJourneySection,
    coursesPricingSection,
    coursesStorySection,
    coursesTextOnlySection,
    coursesTextWithBackgroundImageSection,
    coursesTrainingSection,
  } = coursesPageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Courses And Classes",
        item: {
          url: `${siteUrl}/courses-and-classes`,
          id: `${siteUrl}/courses-and-classes`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/courses-and-classes`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {coursesIntroSection &&
            !checkPropertiesForNull(coursesIntroSection, ["heading"]) && (
              <Hero
                title={
                  <>
                    {coursesIntroSection?.heading}
                    <br className="" />{" "}
                    <span className="text-secondary">
                      {coursesIntroSection?.subHeading}
                    </span>
                  </>
                }
                description={
                  <SafeHtmlParser
                    htmlContent={coursesIntroSection?.description}
                  />
                }
                img={
                  coursesIntroSection.image?.localFile.childImageSharp
                    .gatsbyImageData
                }
                imgAlt={coursesIntroSection.image?.altText}
                ctaButton={coursesIntroSection?.cta}
              />
            )}
          {coursesTextWithBackgroundImageSection &&
            !checkPropertiesForNull(coursesTextWithBackgroundImageSection, [
              "heading",
            ]) && (
              <BgImage
                className="pb-5 pb-lg-7 position-relative bg-secondary pt-5 pt-lg-10 bg-image"
                image={
                  coursesTextWithBackgroundImageSection?.backgroundImage
                    ?.localFile.childImageSharp.gatsbyImageData
                }
                alt={
                  coursesTextWithBackgroundImageSection?.backgroundImage
                    ?.altText
                }
                style={{
                  backgroundSize: "70%",
                  overflow: "hidden",
                }}
              >
                <Container
                  style={{ zIndex: 2 }}
                  className="position-relative pt-lg-4"
                >
                  <Row className="">
                    <Col lg={8} className="">
                      <h2 className="pb-4 text-white display-5">
                        {coursesTextWithBackgroundImageSection?.heading}
                      </h2>
                      <SafeHtmlParser
                        htmlContent={
                          coursesTextWithBackgroundImageSection?.content
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </BgImage>
            )}

          <section className="py-5 py-lg-7">
            <Container>
              <Row className="g-5">
                <Col lg={8}>
                  {coursesTextOnlySection &&
                    !checkPropertiesForNull(coursesTextOnlySection, [
                      "heading",
                    ]) && (
                      <>
                        <h2 className="  fs-2">
                          {coursesTextOnlySection?.heading}
                        </h2>
                        <SafeHtmlParser
                          htmlContent={coursesTextOnlySection?.content}
                        />
                      </>
                    )}
                  {coursesFullWithTextSection &&
                    !checkPropertiesForNull(coursesFullWithTextSection, [
                      "heading",
                    ]) && (
                      <>
                        <h2 className=" mt-5 fs-2">
                          {coursesFullWithTextSection?.heading}
                        </h2>
                        <SafeHtmlParser
                          htmlContent={coursesFullWithTextSection?.content}
                        />
                      </>
                    )}
                </Col>
              </Row>
            </Container>
          </section>

          {coursesStorySection &&
            !checkPropertiesForNull(coursesStorySection, ["heading"]) && (
              <section className="position-relative mt-5 mt-lg-7">
                <div
                  style={{ overflow: "hidden" }}
                  className="h-lg-60 h-md-70 h-80 w-100 bg-primary start-0 bottom-0 position-absolute"
                >
                  <div
                    style={{ top: "-4rem", left: "20%" }}
                    className="position-absolute d-none d-xl-block text-start w-100"
                  >
                    <GatsbyImage
                      className="me-3 w-60"
                      image={
                        coursesStorySection.backgroundImage.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={coursesStorySection.backgroundImage?.altText}
                    />
                  </div>
                  <div
                    style={{ top: "-2rem" }}
                    className="position-absolute start-0 text-end d-none d-md-block d-xl-none h-100 w-100"
                  >
                    <GatsbyImage
                      className="me-3 w-100"
                      image={
                        coursesStorySection.backgroundImage.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={coursesStorySection.backgroundImage?.altText}
                    />
                  </div>
                  <div
                    style={{ top: "2rem" }}
                    className="position-absolute start-0 d-md-none text-end d-xl-none h-100 w-100"
                  >
                    <GatsbyImage
                      className="me-3 w-100"
                      style={{ width: "180%" }}
                      image={
                        coursesStorySection.backgroundImage.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={coursesStorySection.backgroundImage?.altText}
                    />
                  </div>
                </div>
                <Container style={{ zIndex: 3 }} className="position-relative">
                  <Row>
                    <Col className="text-center">
                      {" "}
                      <LazyLoad height={700} offset={100}>
                        <div className="pb-5 ">
                          <ReactPlayer
                            controls
                            className="w-100 h-auto"
                            url={coursesStorySection?.video?.mediaItemUrl}
                          />
                        </div>
                      </LazyLoad>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="text-center" lg={8}>
                      <h2 className="pb-4 text-white display-5">
                        {coursesStorySection?.heading}
                      </h2>
                      {coursesStorySection.description && (
                        <div className="text-white pb-5">
                          <SafeHtmlParser
                            htmlContent={coursesStorySection.description}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          {coursesAimSection &&
            !checkPropertiesForNull(coursesAimSection, ["heading"]) && (
              <section
                style={{ overflow: "hidden" }}
                className="py-5 py-lg-7 position-relative "
              >
                <div
                  style={{ top: "4rem", right: "-2rem" }}
                  className="position-absolute d-none d-xl-block text-end w-100"
                >
                  <GatsbyImage
                    className="w-60"
                    image={
                      coursesAimSection.image?.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    alt={coursesAimSection.image?.altText}
                  />
                </div>
                <div
                  style={{ top: "-2rem" }}
                  className="position-absolute start-0 text-end d-none d-md-block d-xl-none h-100 w-100"
                >
                  <GatsbyImage
                    className="w-100"
                    image={
                      coursesAimSection.image?.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    alt={coursesAimSection.image?.altText}
                  />
                </div>
                <div
                  style={{ top: "-2rem" }}
                  className="position-absolute start--40 text-end d-md-none h-100 w-100"
                >
                  <GatsbyImage
                    style={{ width: "180%" }}
                    image={
                      coursesAimSection.image?.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    alt={coursesAimSection.image?.altText}
                  />
                </div>
                <Container
                  style={{ zIndex: 2 }}
                  className="position-relative pt-lg-4"
                >
                  <Row className="">
                    <Col lg={8} className="">
                      <h2 className="pb-4  fs-2">
                        {coursesAimSection?.heading}
                      </h2>
                      <SafeHtmlParser
                        htmlContent={coursesAimSection?.content}
                      />
                      {coursesAimSection.aimElements &&
                        coursesAimSection.aimElements.length > 0 && (
                          <div className="ps-md-4">
                            {coursesAimSection.aimElements.map((item) => (
                              <div className="d-flex align-items-start w-100 pb-3" key={uuid4()}>
                                <GatsbyImage
                                  style={{ width: "20px", marginTop: "0.2rem" }}
                                  className="me-3"
                                  image={
                                    item.icon?.localFile.childImageSharp
                                      .gatsbyImageData
                                  }
                                  alt={item.icon?.altText}
                                />
                                <p className="d-inline-block mb-0 pb-0" style={{ flex: 1 }}>
                                  {item.heading}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                      <SafeHtmlParser
                        htmlContent={coursesAimSection?.belowContent}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}

          {coursesPricingSection &&
            !checkPropertiesForNull(coursesPricingSection, ["heading"]) && (
              <BgImage
                className="py-5 pt-lg-5 pt-xl-5 position-relative bg-secondary bg-right-image"
                image={
                  coursesPricingSection?.backgroundImage?.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={coursesPricingSection?.backgroundImage?.altText}
                style={{
                  backgroundPosition: "left center",
                }}
              >
                <Container
                  style={{ zIndex: 2 }}
                  className="position-relative text-center "
                >
                  <Row className="justify-content-center">
                    <Col className="text-center">
                      <h2 className="pb-4 text-white display-5">
                        {coursesPricingSection?.heading}
                      </h2>
                    </Col>
                  </Row>
                  <Row className="align-items-center justify-content-center g-3 g-xl-7">
                    <Col lg={10} xl={8}>
                      <div
                        style={{
                          background: "rgba(0, 0, 0, 0.32)",
                          borderRadius: "6px",
                        }}
                        className="w-100 h-100 text-white py-3 px-4 "
                      >
                        <div className="pb-0 mb-0 fs-5">
                          <SafeHtmlParser
                            htmlContent={coursesPricingSection?.description}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {coursesPricingSection.cta &&
                    coursesPricingSection.cta.url && (
                      <Row>
                        <Col>
                          <Button
                            as={Link}
                            to={coursesPricingSection.cta.url}
                            variant="primary"
                            className="w-100 w-md-auto py-3 mt-4 text-white px-5"
                            target={coursesPricingSection.cta.target ?? "_self"}
                          >
                            {coursesPricingSection.cta.title}
                          </Button>
                        </Col>
                      </Row>
                    )}
                </Container>
              </BgImage>
            )}

          {coursesTrainingSection &&
            !checkPropertiesForNull(coursesTrainingSection, ["heading"]) && (
              <section>
                <Container className=" pt-5 pb-lg-5">
                  {coursesTrainingSection.imageGallery &&
                    coursesTrainingSection.imageGallery.length > 0 && (
                      <Row className="g-3 ">
                        {coursesTrainingSection.imageGallery.map((item) => (
                          <Col lg={4} key={uuid4()}>
                            <GatsbyImage
                              className="w-100"
                              image={
                                item?.localFile.childImageSharp.gatsbyImageData
                              }
                              alt={item?.altText}
                            />{" "}
                          </Col>
                        ))}
                      </Row>
                    )}
                  <Row>
                    <Col lg={10} xl={8}>
                      <h2 className=" fs-2 mt-4">
                        {coursesTrainingSection?.heading}
                      </h2>
                      <SafeHtmlParser
                        htmlContent={coursesTrainingSection?.description}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}

          <section
            style={{
              background:
                "linear-gradient(200deg, rgba(245, 249, 252, 0.00) 3.13%, #F5F9FC 51.56%, rgba(245, 249, 252, 0.00) 100%), linear-gradient(180deg, #F5F9FC 0%, #F5F9FC 0.01%, rgba(250, 252, 254, 0.00) 31.77%, rgba(250, 252, 254, 0.00) 65.63%, #F5F9FC 100%), radial-gradient(116.72% 75.58% at 101.01% 32.67%, rgba(255, 147, 140, 0.41) 2.08%, rgba(245, 249, 252, 0.41) 100%), radial-gradient(126.79% 78.09% at -2.67% 63.84%, rgba(116, 194, 218, 0.42) 2.08%, rgba(245, 249, 252, 0.42) 100%)",
            }}
            className="py-5 py-lg-7"
            id="form"
          >
            <Container>
              <Row className="justify-content-center">
                <Col lg={8} xl={7}>
                  <h2 className="display-5 mb-4 text-center">
                    {coursesContactSection.formHeading ??
                      "Book a discovery call"}
                  </h2>
                  <ContactForm btnText={coursesContactSection?.buttonLabel} />
                </Col>
              </Row>
            </Container>
          </section>
          {coursesAboutSection &&
            !checkPropertiesForNull(coursesAboutSection, ["heading"]) && (
              <section className="pb-5 pt-md-5 py-xl-7">
                <Container>
                  <Row className="g-5 g-lg-6 align-items-center">
                    <Col md={4}>
                      <GatsbyImage
                        className="w-100"
                        image={
                          coursesAboutSection.image?.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        alt={coursesAboutSection.image?.altText}
                      />
                    </Col>
                    <Col md={8}>
                      <h2 className=" fs-2 mt-4  ">
                        {coursesAboutSection?.heading}
                      </h2>
                      <h3 className="text-secondary fs-4">
                        {coursesAboutSection?.subHeading}
                      </h3>
                      <SafeHtmlParser
                        htmlContent={coursesAboutSection?.content}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          <BgImage
            className="py-5 pt-lg-7 pt-xl-9 position-relative bg-secondary bg-right-image"
            image={
              coursesFaqSection?.backgroundImage?.localFile.childImageSharp
                .gatsbyImageData
            }
            alt={coursesFaqSection?.backgroundImage?.altText}
            style={{
              backgroundPosition: "left center",
            }}
          >
            <Container
              style={{ zIndex: 2 }}
              className="position-relative text-center "
            >
              <Faq
                heading={coursesFaqSection?.heading}
                faqs={coursesFaqSection?.faq}
              />
            </Container>
          </BgImage>
          {coursesJourneySection &&
            !checkPropertiesForNull(coursesJourneySection, ["heading"]) && (
              <BgImage
                className="position-relative py-5 py-lg-7 py-xl-10"
                image={
                  coursesJourneySection?.backgroundImage?.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={coursesJourneySection?.backgroundImage?.altText}
              >
                <section style={{ overflow: "hidden" }}>
                  <Container
                    style={{ zIndex: 3 }}
                    className="mt-xl-10 position-relative"
                  >
                    <Row className="justify-content-center">
                      <Col className="text-center" lg={10}>
                        <h2 className="pb-5 display-3 d-none d-lg-block">
                          <SafeHtmlParser
                            htmlContent={coursesJourneySection?.heading}
                          />
                        </h2>
                        <h2 className="pb-5  display-5 d-lg-none">
                          <SafeHtmlParser
                            htmlContent={coursesJourneySection?.heading}
                          />
                        </h2>
                        {coursesJourneySection.cta &&
                          coursesJourneySection.cta?.url && (
                            <Button
                              as={Link}
                              to={coursesJourneySection.cta?.url}
                              variant="primary"
                              className="w-100 w-md-auto py-3  text-white px-5"
                              target={
                                coursesJourneySection.cta?.target ?? "_self"
                              }
                            >
                              {coursesJourneySection.cta?.title}
                            </Button>
                          )}
                      </Col>
                    </Row>
                  </Container>
                </section>
              </BgImage>
            )}
        </Layout>
      </div>
    </>
  );
};

export default CoursesAndClassesPage;
